@tailwind base;
@tailwind components;
@tailwind utilities;

body:before {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  min-height: max-content;
  z-index: -10;
  background: url('/src/assets/gallery/DSC_0574.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
